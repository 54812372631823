<template>
  <div
    class="cb-message resume-proposal"
    :class="{'is-bot' : data.isBot}">
    <span
      ref="icon"
      class="cb-message-icon">
      <avatar-bot />
    </span>
    <div
      ref="text"
      class="cb-message-text">
      <span class="time-hour">{{ timeHour }}</span>
      <div ref="card">
        <v-card class="elevation-0 my-1">
          <v-toolbar
            dark
            color="channelSms"
            height="60"
            flat>
            <v-avatar
              color="grey lighten-4"
              size="80">
              <img
                src="@/assets/images/fake/senac.jpg"
                :alt="`Logo da empresa ${company.fantasyName}`">
            </v-avatar>
          </v-toolbar>
          <v-toolbar-title class="darkGray--text text-center mx-0 mb-3">
            {{ company.fantasyName }}
          </v-toolbar-title>

          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header
                :is-active="true"
                :expand-icon="$root.icons.layout.menu_down">
                <v-layout>
                  <v-flex
                    xs12
                    md12>
                    <b>Dados da empresa</b>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout>
                  <v-flex
                    xs12
                    md12>
                    <span>CNPJ: </span>
                    {{ company.cnpj }}
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex
                    xs12
                    md12>
                    <span>Inscrição estadual:</span>
                    {{ company.stateRegistration }}
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex
                    xs12
                    md12>
                    <span>Site:</span>
                    {{ company.website }}
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex
                    xs12
                    md12>
                    <span>E-mail:</span>
                    {{ company.email }}
                  </v-flex>
                </v-layout>
                <!-- <v-divider class="mspan-2" /> -->
                <v-layout>
                  <v-flex
                    xs12
                    md12>
                    <span>Endereço: </span>
                    {{ company.address[0].address }}, nº {{ company.address[0].number }}
                  </v-flex>
                </v-layout>

                <v-layout v-if="company.address[0].complement.length > 0">
                  <v-flex
                    xs12
                    md12>
                    <span>Complemento</span>
                    {{ company.address[0].complement }}
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex
                    xs12
                    md12>
                    <span>Cidade: </span>
                    {{ company.address[0].city }}
                    - <span>UF</span>
                    {{ company.address[0].uf }}
                    - <span>CEP</span>
                    {{ company.address[0].zipCode }}
                  </v-flex>
                </v-layout>
                <!-- <v-divider class="my-1" /> -->
                <!-- <v-layout>
                  <v-flex xs12 md12>
                    <span>UF</span>
                    {{ company.address[0].uf }}
                  </v-flex>
                </v-layout> -->
                <!-- <v-layout>
                  <v-flex xs12 md12>
                    <span>CEP</span>
                    {{ company.address[0].zipCode }}
                  </v-flex>
                </v-layout> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider class="my-1" />
          <v-layout>
            <v-flex
              xs12
              md6>
              <b>Valor Original</b>
            </v-flex>
            <v-flex
              xs12
              md6
              class="text-right">
              {{ $n(parcelSelected.originalValue, 'currency') }}
            </v-flex>
          </v-layout>
          <v-divider class="my-1" />
          <v-expansion-panels>
            <v-expansion-panel
              ref="addition"
              :expand-icon="$root.icons.layout.menu_down">
              <v-expansion-panel-header>
                <v-layout>
                  <v-flex
                    xs12
                    md6>
                    <b>Acréscimos legais</b>
                  </v-flex>
                  <v-flex
                    v-if="showAdditionLabel"
                    xs12
                    md6
                    class="text-right">
                    <span>{{ $n(addition, 'currency') }}</span>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout
                  v-for="detail in proposal.details"
                  :key="detail.type"
                  class="details">
                  <v-flex
                    xs12
                    md8>
                    <span>{{ $t('calculateParams.' + detail.type) }}</span>
                  </v-flex>
                  <v-flex
                    xs12
                    md4
                    class="text-right">
                    {{ $n(detail.value, 'currency') }}
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-divider class="my-1" />

          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <b>Valor corrigido</b>
            </v-flex>
            <v-flex
              xs12
              md6
              class="text-right">
              <span>{{ $n(proposal.currentValue, 'currency') }}</span>
            </v-flex>
          </v-layout>
          <v-divider class="my-1" />

          <!-- <v-layout>
            <v-flex xs12 md6 class="">
              <b>Desconto</b>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <b class="success--text">{{ $n(discount, 'currency') }}</b>
            </v-flex>
          </v-layout>
          <v-divider class="my-1" /> -->
          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <b class="success--text">Descontos</b>
            </v-flex>
            <v-flex
              xs12
              md6
              class="text-right">
              <span class="success--text">{{ $n(discount, 'currency') }}</span>
            </v-flex>
          </v-layout>
          <v-divider class="my-1" />
          <v-layout>
            <v-flex
              xs12
              md6
              class="">
              <b>{{ setLabelParcel }}</b>
            </v-flex>
            <v-flex
              v-if="parcelSelected.parcel === 1"
              xs12
              md6
              class="text-right">
              <b>{{ $n(parcelSelected.parcelValue, 'currency') }}</b>
            </v-flex>
            <v-flex
              v-else
              xs12
              md6
              class="text-right">
              <b>{{ parcelSelected.parcel }}x {{ $n(parcelSelected.parcelValue, 'currency') }}</b>
            </v-flex>
          </v-layout>

          <v-divider class="my-1" />
          <div class="resume-proposal-footer">
            <small>A proposta {{ proposal.code }} é válida <br>até dia {{ data.options.date }}</small>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  import chatUiMixin from '@/mixins/chat-ui'
  import AvatarBot from '../AvatarBot'

  export default {
    components: { AvatarBot },
    mixins: [ chatUiMixin ],
    data () {
      return {
        showAdditionLabel: true
      }
    },
    computed: {
      proposal () {
        return this.data.options.proposal
      },
      company () {
        return this.proposal.company
      },
      parcelSelected () {
        let index = this.data.options.parcel - 1
        return this.proposal.installments[index]
      },
      discount () {
        let parcel = this.data.options.parcel - 1
        return this.proposal.currentValue - this.proposal.installments[parcel].currentValue
      },
      setLabelParcel () {
        return this.data.options.parcel === 1 ? 'Proposta à vista' : 'Proposta parcelada em'
      },
      addition () {
        return this.proposal.details.reduce((total, item) => total + item.value, 0)
      }
    },
    mounted () {
      this.iconAnimation()
      this.messageAnimation()

      this.$watch(
        () => {
          let active = this.$refs.addition.isActive
          this.showAdditionLabel = !active
        }
      )
    }
  }
</script>

<style lang="sass">
  .resume-proposal
    display: flex
    align-items: flex-start

    .cb-message-text
      margin-top: 30px
      font-size: 14px
      width: 340px

      .v-expansion-panel
        &::before
          box-shadow: none !important

      .v-expansion-panel-header
        padding: 0
        color: $textColor
        min-height: unset

        &__icon
          padding-right: 2px

          .v-icon
            color: $accent
            font-size: 20px

      .v-expansion-panel-content__wrap
        padding: 8px 16px 16px

    .resume-proposal-footer
      width: 100%
      text-align: center
      // background: $bgColor

      small
        line-height: 1.3
        display: block

      .v-toolbar__title
        padding-top: 10px

    .v-expansion-panel__body
      padding-top: 0
      color: $textColor

    .v-toolbar__title
      padding-top: 50px

    .v-card
      // max-width: 360px
      color: $textColor

      .v-avatar
        position: absolute
        bottom: -35px
        left: 50%
        transform: translateX(-50%)
        box-shadow: 0px 0px 0px 4px $bgColor, 0px 0px 0px 8px $light

      .layout
        margin: 3px

      .details:last-child
        border-bottom: 1px solid $gray

  .v-expansion-panel:not(:first-child):after
    border-top: none !important

  @media screen and (max-width: $max-mobile)
    .resume-proposal
      .cb-message-text
        width: 320px
</style>
